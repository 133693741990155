<template>
  <Error404 />
</template>
<script>
import Error404 from "@/components/Error/404.vue";

export default {
  components: {
    Error404,
  },
};
</script>
